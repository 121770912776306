import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Post = () => {
   const [posts, setPosts] = useState([]);

   // GET with fetch API
   useEffect(() => {
      const fetchPost = async () => {
         const response = await fetch(
            'https://dataworld.xyz/wp-json/wp/v2/posts/?per_page=10&_method=GET',{
                headers: {
                    'Accept': '*/*', 
                    'Access-Control-Allow-Origin':'*',
                    'Origin': 'http://localhost:3000',
                    'Referer': 'http://localhost:3000/',
                 },
            }
         );
         const data = await response.json();
         console.log(data);
         setPosts(data);
      };
      fetchPost();
   }, []);

   return (
    <div className="container" >
    <div className="row row-cols-3 row-cols-md-3 g-2">
      {posts.map((post) => {
         return (
                <div className="col">
                    <div className="card h-100">
                    <div className="card-body">
                    <Link to={`/${post.id}`}>
                        <img src={post.jetpack_featured_media_url} className="card-img-top" alt="thumb"/> 
                        <h5 className="card-title" dangerouslySetInnerHTML={{__html: post.title.rendered}} />
                        <span className='badge bg-danger'>{post.date} | {post.guid.rendered}</span>
                    </Link>
                    </div>
                    </div>
                </div>
            
         );
      })}
   </div>
   </div>
   );
};

export default Post;
