import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

function Singlepost() {
  const { id } = useParams();

  const url = `https://dataworld.xyz//wp-json/wp/v2/posts/${id}?_method=GET`;
  const [singlePost, setPost] = useState(null);

  let content = null;

  useEffect(() => {
    axios.get(url).then((response) => {
      setPost(response.data);
    });
  }, [url]);

  if (singlePost) {
    return (content = (
      <div className="container">
        <h1>{singlePost.title.rendered}</h1>
       <img src={singlePost.jetpack_featured_media_url} className="img-fluid" alt="img" />
        <p dangerouslySetInnerHTML={{__html: singlePost.content.rendered}} />
      </div>
    ));
  }

  return <div>{content}</div>;
}

export default Singlepost;