import './App.css';
import Post from './Post';
import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Singlepost from "./PostDetail";
import CopyPost from "./CopyPost";

function App() {
  return (


<Router>

  <Routes>
    <Route exact path="/" element={<CopyPost/>}/>
    <Route exact path="/post" element={<Post/>}/>
    <Route exact path="/:id" element={<Singlepost/>}/>

  </Routes>

</Router>
    
  );
}

export default App;