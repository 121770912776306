import React, { useState } from "react";

function CopyPost() {
  const [result, setResult] = useState([]);
  const [title, setTitle] = useState([]);

  // GET with fetch API
  async function handleSubmit(e) {
    e.preventDefault();
    console.log(e.target.elements.url.value);
    const fullurl = `${e.target.elements.url.value}?_method=GET`;
    const response = await fetch(
      fullurl,
      {
           headers: {
               'Accept': '*/*', 
            },
       }
    );
    
    if (e.target.elements.url.value) {
      e.target.elements.submit.setAttribute("disabled", "disabled");
      e.target.elements.reload.classList.remove("d-none");
      if (response.status===200){
        const data = await response.json();
        console.log(data);
        setResult(data.content.rendered);
        setTitle(data.title.rendered);
      } else{
        setResult("Error: " + response.status);
        setTitle("Error: " + response.status);
      }
      
    };
  }

  function refreshPage() {
    window.location.reload(true);
  }

  return <div className="container mt-2">
  <form onSubmit={handleSubmit}>
    <h3 className="text-center">Get WP Post from JSON</h3>
  <div className="form-group my-2">
    <label htmlFor="url">Post URL</label>
    <input type="text" className="form-control" id="url" name="url" placeholder="Paste post json url here" />
    <span className="badge bg-danger mt-2">https://site.com/wp-json/wp/v2/posts/4407</span>
  </div>

  <div className="form-group mt-2">
    <label htmlFor="url">Title</label>
    <input type="text" className="form-control" defaultValue={title}  />
  </div>

  <div className="form-group mt-2">
    <label htmlFor="result">Post</label>
    <textarea className="form-control" id="result" rows={8} defaultValue={result} />
  </div>
  <div className="form-group">
    <input name="submit" type="submit"  value='Get Post' className="btn btn-lg btn-danger btn-block my-2"></input>
    <button type="button" name="reload" className="d-none m-5 btn btn-lg btn-warning" onClick={refreshPage} >Get Another</button>
  </div>
</form>
</div>;
}

export default CopyPost;